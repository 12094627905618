import request from '@/plugins/axios'

export function listApi(params) {
  // 分页获取
  return request({
    url: `/mark/youtube_accounts`,
    method: 'get',
    params
  })
}
export function addApi(data) {
  // 新增youtube账号标签
  return request({
    url: `/mark/youtube_accounts`,
    method: 'post',
    data
  })
}
export function batch_tagApi(youtube_account_id, ids) {
  // youtube账号多个标签
  return request({
    url: `/mark/youtube_accounts/${youtube_account_id}:batch_tag`,
    method: 'post',
    data: {
      ids: ids
    }
  })
}
export function detailApi(id) {
  // 获取指定youtube账号标签
  return request({
    url: `/mark/youtube_accounts/${id}`,
    method: 'get'
  })
}
export function delApi(id) {
  // 删除
  return request({
    url: `/mark/youtube_accounts/${id}`,
    method: 'delete'
  })
}
// 批量更新账号标签
export function batchTagApi(id, data) {
  // 删除
  return request({
    url: `/mark/youtube_accounts/${id}:batch_update_tag`,
    method: 'post',
    data
  })
}
